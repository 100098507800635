/* eslint-disable camelcase */
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useLoginMutation } from "src/react-query/hooks/auth";
import { forgotPasswordModalState, signInModalState } from "src/recoil/commonRecoilState";
import isNumeric from "src/utils/isNumeric";
import { isRegexVietnamesePhoneNumber } from "src/validations";

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 7, span: 16 },
};

export default function SignInModal() {
  const loginMutation = useLoginMutation();
  const router = useRouter();
  const isFromLanding = router.query?.["landing"];

  useEffect(() => {
    if (isFromLanding === "sign-in") {
      setIsSignInModalModal(true);
    }
  }, [isFromLanding]);

  const [form] = Form.useForm();
  const initialValues = {
    phone_number: "",
    password: "",
  };

  const [isSignInModalOpen, setIsSignInModalModal] = useRecoilState(signInModalState);

  const handleCancelSignInModal = () => {
    setIsSignInModalModal(false);
    form.resetFields();
  };
  const setIsForgotPasswordModalModal = useSetRecoilState(forgotPasswordModalState);

  const submitForm = async (data) => {
    const { phone_number, password } = data;
    const payload = { phone_number, password };
    loginMutation.mutate(payload);
    form.resetFields();
  };

  const handleClickForgotPassword = () => {
    setIsForgotPasswordModalModal((pre) => ({ ...pre, isOpen: true }));
    handleCancelSignInModal();
  };
  return (
    <Modal
      maskClosable={false}
      title={<h2 className="tw-px-[20px] tw-font-bold tw-text-[20px]">Đăng nhập</h2>}
      open={isSignInModalOpen}
      onCancel={handleCancelSignInModal}
      footer={null}
      bodyStyle={{ padding: "40px" }}
    >
      <Form
        {...layout}
        form={form}
        onFinish={submitForm}
        initialValues={initialValues}
        colon={false}
      >
        <Form.Item
          label={
            <p className="tw-font-medium tw-text-[16px] tw-relative tw-top-2">Số điện thoại</p>
          }
          name="phone_number"
          rules={[
            () => ({
              required: true,
              validator(_, value) {
                const phoneNumber = value.trim();
                const validate = isRegexVietnamesePhoneNumber(value);
                if (!phoneNumber) {
                  return Promise.reject("Vui lòng nhập số điện thoại");
                }
                if (isNaN(phoneNumber)) {
                  return Promise.reject("Số điện thoại không hợp lệ");
                }
                if (!validate) {
                  return Promise.reject("Số điện thoại không hợp lệ");
                }
                return Promise.resolve();
              },
            }),
          ]}
          normalize={(value) => {
            if (!isNumeric(value)) {
              return value?.substring(0, value.length - 1).trim();
            }
            return value.trim();
          }}
        >
          <Input className="focus:tw-border-red focus:tw-shadow-button tw-h-[40px]" />
        </Form.Item>

        <Form.Item
          label={<p className="tw-font-medium tw-text-[16px] tw-relative tw-top-2">Mật khẩu</p>}
          name="password"
          rules={[
            {
              required: true,
              validator(_, value) {
                if (!value) {
                  return Promise.reject("Vui lòng nhập mật khẩu");
                }

                if (!value.trim()) {
                  return Promise.reject("Mật khẩu không hợp lệ");
                }

                if (value.length < 6) {
                  return Promise.reject("Mật khẩu lớn hơn 6 kí tự");
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password className="focus:tw-border-red focus:tw-shadow-button  tw-h-[40px]" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
          <div className="tw-flex tw-justify-between flex-wrap md:tw-flex-row tw-flex-col tw-gap-[15px]">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Ghi nhớ mật khẩu</Checkbox>
            </Form.Item>

            <span onClick={handleClickForgotPassword} className="tw-text-blue tw-cursor-pointer">
              Quên mật khẩu
            </span>
          </div>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            loading={loginMutation.isLoading}
            className="tw-bg-button !tw-border-0 tw-min-w-[150px] tw-mt-[20px] tw-text-[20px] tw-h-[40px] tw-font-semibold"
            type="primary"
            htmlType="submit"
          >
            Đăng nhập
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
