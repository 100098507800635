/* eslint-disable camelcase */
import { Button, Form, Input, Modal } from "antd";
import { useRecoilState } from "recoil";
import { useForgotPasswordMutation, useLoginMutation } from "src/react-query/hooks/auth";
import { forgotPasswordModalState, FORGOT_PASSWORD_STATUS } from "src/recoil/commonRecoilState";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 7, span: 16 },
};

export default function ForgotPasswordModal() {
  const loginMutation = useLoginMutation();
  const forgotPasswordMutation = useForgotPasswordMutation();

  const [form] = Form.useForm();
  const initialValues = {
    phone_number: "",
    password: "",
  };

  const [isForgotPasswordModalOpen, setIsForgotPasswordModalModal] =
    useRecoilState(forgotPasswordModalState);

  const handleCancelForgotPasswordModal = () => {
    setIsForgotPasswordModalModal((pre) => ({
      ...pre,
      isOpen: false,
      status: FORGOT_PASSWORD_STATUS.FORM,
    }));
    form.resetFields();
  };

  const submitForm = async (data) => {
    const { email } = data;
    forgotPasswordMutation.mutate({ email });

    // loginMutation.mutate(payload);
    form.resetFields();
  };

  return (
    <Modal
      maskClosable={false}
      title={
        <h2 className="tw-px-[20px] tw-font-bold tw-text-[20px] tw-text-blue">
          {isForgotPasswordModalOpen.status === FORGOT_PASSWORD_STATUS.FORM
            ? "Quên mật khẩu"
            : "Thông báo"}
        </h2>
      }
      open={isForgotPasswordModalOpen.isOpen}
      onCancel={handleCancelForgotPasswordModal}
      footer={null}
      bodyStyle={{ padding: "40px" }}
    >
      {isForgotPasswordModalOpen.status === FORGOT_PASSWORD_STATUS.FORM ? (
        <Form
          {...layout}
          form={form}
          onFinish={submitForm}
          initialValues={initialValues}
          colon={false}
        >
          <Form.Item
            label={<p className="tw-font-medium tw-text-[16px] tw-relative tw-top-2">E-mail</p>}
            name="email"
            rules={[
              {
                type: "email",
                message: "E-mail không hợp lệ !",
              },
              {
                required: true,
                message: "Vui lòng nhập E-mail của bạn!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              loading={loginMutation.isLoading}
              className="tw-bg-button !tw-border-0 tw-min-w-[150px] tw-mt-[20px] tw-text-[20px] tw-h-[40px] tw-font-semibold"
              type="primary"
              htmlType="submit"
            >
              Xác nhận
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <h3 className="tw-text-blue tw-text-[16px]">
          Lấy lại mật khẩu thành công. Mật khẩu đã được gửi vào E-mail của bạn !{" "}
        </h3>
      )}
    </Modal>
  );
}
