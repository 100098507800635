import Head from "next/head";

const LayoutDocument = ({
  children,
  title = "Autoshine hệ thống rửa xe tự động tiêu chuẩn Đức",
}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
      </Head>
      <div className="tw-flex tw-flex-col  tw-min-h-screen"> {children} </div>
    </div>
  );
};
export default LayoutDocument;
