import { Breadcrumb, Popover } from "antd";
import Link from "next/link";
import { Fragment } from "react";

const textWhite16px = `!tw-text-[white] !tw-text-[16px]`;
const separator18px = `!tw-text-orange !tw-text-[18px] tw-mr-[20px]`;

export const Menu = ({ name, href }) => {
  return (
    <Link className={textWhite16px} href={href}>
      <a className={textWhite16px}>{name}</a>
    </Link>
  );
};

export const SubMenu = ({ name, submenu }) => {
  const content = (
    <div className="tw-h-full tw-flex tw-gap-[8px] tw-flex-col tw-px-[30px] tw-py-[15px] tw-backdrop-brightness-[0.3]">
      {submenu.map(({ id, name, href }) => {
        return <Menu key={id} name={name} href={href} />;
      })}
    </div>
  );
  return (
    <>
      <Popover
        Popover
        content={content}
        trigger="hover"
        overlayClassName="custom-popover tw-backdrop-brightness-[0.3]"
        placement="bottom"
      >
        <div className="tw-cursor-pointer">{name}</div>
      </Popover>
      <style jsx global>{`
        .custom-popover .ant-popover-inner-content {
          padding: 0px;
        }
        .custom-popover .ant-popover-inner {
          background: transparent;
        }
      `}</style>
    </>
  );
};
export default function TBreadcrumb({ routesNavbar }) {
  return (
    <Breadcrumb className="tw-text-[white]" separator="">
      {routesNavbar.map(({ id, name, href, separator, submenu }) => (
        <Fragment key={id}>
          <Breadcrumb.Item className={textWhite16px}>
            {submenu ? <SubMenu name={name} submenu={submenu} /> : <Menu name={name} href={href} />}
          </Breadcrumb.Item>
          <Breadcrumb.Separator>
            <span className={separator18px}> {separator ? ">" : ""}</span>
          </Breadcrumb.Separator>
        </Fragment>
      ))}
    </Breadcrumb>
  );
}
