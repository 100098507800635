/* eslint-disable @next/next/no-img-element */
import { Button, Collapse, Drawer } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { AiFillCloseCircle, AiOutlineMenu } from "react-icons/ai";
import { useRecoilValue } from "recoil";
import Logo from "src/assets/images/logo.png";
import AvatarAuth from "src/components/Header/AvatarAuth";
import TBreadcrumb from "src/components/Header/Breadcrumb";
import ButtonGroupAuthentication from "src/components/Header/BtnGroupRightHeader";
import { routerConstant } from "src/constants/routerConstant";
import useWindowSize from "src/hooks/useWindowSize";
import { authState } from "src/recoil/authRecoilState";
import screens from "src/utils/screens";

export const routesNavbar = [
  {
    id: 1,
    name: "Giới thiệu Autoshine",
    href: "https://autoshine.com.vn/gioi-thieu-autoshine/",
    active: false,
    separator: true,
  },
  // {
  //   id: 2,
  //   name: "Dịch vụ rửa xe",
  //   href: routerConstant.serviceIntroduction,
  //   active: false,
  //   separator: true,
  //   submenu: [
  //     {
  //       id: 21,
  //       name: "Giới thiệu chung",
  //       href: routerConstant.serviceIntroduction,
  //       active: false,
  //       separator: true,
  //     },
  //     {
  //       id: 22,
  //       name: "Tất cả dịch vụ",
  //       href: routerConstant.service,
  //       active: false,
  //       separator: true,
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   name: "Chăm sóc xe",
  //   href: routerConstant.carCareIntroduction,
  //   active: false,
  //   separator: true,
  //   submenu: [
  //     {
  //       id: 31,
  //       name: "Giới thiệu chung",
  //       href: routerConstant.carCareIntroduction,
  //       active: false,
  //     },
  //     {
  //       id: 32,
  //       name: "Tất cả dịch vụ",
  //       href: routerConstant.carCare,
  //       active: false,
  //     },
  //   ],
  // },
  {
    id: 4,
    name: "Tin tức",
    href: "https://autoshine.com.vn/tin-tuc/",
    active: false,
    separator: true,
  },
  {
    id: 5,
    name: "Auto Coffee",
    href: "https://autoshine.com.vn/gioi-thieu-auto-coffee/",
    active: false,
    separator: true,
  },
  {
    id: 6,
    name: "Sản phẩm",
    href: routerConstant.products,
    active: false,
    separator: true,
  },
];

export const LogoPage = () => {
  const router = useRouter();
  return (
    <div
      onClick={() => router.push("https://autoshine.com.vn/")}
      className="tw-text-blue tw-cursor-pointer"
    >
      <img
        className="tw-min-w-[100px] tw-w-[150px] md:tw-w-[160px] xl:tw-w-[270px]"
        src={Logo.src}
        alt="logo"
        height="52"
      />
    </div>
  );
};

export default function Header() {
  const { width } = useWindowSize();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [placement] = useState("left");
  const auth = useRecoilValue(authState);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <div className="tw-h-header tw-backdrop-brightness-[0.3] tw-z-[99] tw-w-screen tw-px-[10px] tw-left-0 tw-right-0 tw-top-0 tw-fixed">
      <div className="tw-max-w-max-container tw-flex tw-items-center tw-justify-between tw-m-auto tw-h-full">
        <LogoPage />
        {width > screens.lg ? (
          <div className="tw-hidden lg:tw-flex ">
            <div className="tw-nav-bar tw-pl-[12px] lg:tw-pr-[40px] xl:tw-pr-[100px] tw-flex tw-items-center">
              <TBreadcrumb routesNavbar={routesNavbar} />
            </div>

            {auth?.status ? <AvatarAuth /> : <ButtonGroupAuthentication />}
          </div>
        ) : (
          <div className="tw-flex lg:tw-hidden  ">
            <Button type="button" onClick={showDrawer} className="tw-border-orange-dark">
              <AiOutlineMenu className="tw-text-orange-dark" fontSize={24} />
            </Button>
            <Drawer
              width={320}
              title={
                <div className="tw-flex tw-items-center tw-justify-between">
                  <LogoPage />
                  <div className="tw-cursor-pointer" onClick={onCloseDrawer}>
                    <AiFillCloseCircle className="tw-text-black" fontSize={24} />
                  </div>
                </div>
              }
              placement={placement}
              closable={false}
              onClose={onCloseDrawer}
              open={openDrawer}
              key={placement}
            >
              <ul className="tw-space-y-[20px] tw-font-bold">
                {routesNavbar.map(({ id, name, href, submenu }) => (
                  <li key={id}>
                    {submenu ? (
                      <Collapse className="tw-border-none" expandIconPosition="end">
                        <Collapse.Panel
                          header=<div className="tw-font-bold tw-text-[16px]">{name}</div>
                          className="!tw-border-none !tw-rounded-none tw-bg-white custom-collapse"
                        >
                          <div className="tw-flex tw-flex-col tw-gap-[8px]">
                            {submenu.map(({ id, href, name }) => {
                              return (
                                <Link href={href} key={id}>
                                  <a className="!tw-text-[black]" key={id}>
                                    {name}
                                  </a>
                                </Link>
                              );
                            })}
                          </div>
                        </Collapse.Panel>
                      </Collapse>
                    ) : (
                      <Link href={href}>
                        <a className="!tw-text-[black]">{name}</a>
                      </Link>
                    )}
                  </li>
                ))}
                <style jsx global>{`
                  .custom-collapse .ant-collapse-header {
                    padding: 0px !important;
                  }
                `}</style>
              </ul>
              <div className="tw-font-bold tw-mt-[20px]">
                {auth?.status ? <AvatarAuth /> : <ButtonGroupAuthentication />}
              </div>
            </Drawer>
          </div>
        )}
      </div>
    </div>
  );
}
