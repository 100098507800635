import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import authApi from "src/api/authApi";
import { useGetUserInfo } from "src/react-query/hooks/user";
import { handleResultApi } from "src/react-query/utils/handleResultApi";
import { authState } from "src/recoil/authRecoilState";
import {
  forgotPasswordModalState,
  FORGOT_PASSWORD_STATUS,
  signInModalState,
  signUpModalState,
} from "src/recoil/commonRecoilState";
import { addCookie } from "src/utils/cookie";

export const useLoginMutation = (options) => {
  const userQuery = useGetUserInfo({ enabled: false });

  const setIsSignInModalModal = useSetRecoilState(signInModalState);
  const setAuth = useSetRecoilState(authState);
  return useMutation({
    mutationFn: authApi.login,
    onSuccess: async (response) => {
      const accessToken = response?.data?.access_token;
      const phoneNumber = response?.data?.phone_number;
      const payload = { accessToken, phoneNumber };
      addCookie(payload);
      setAuth({ ...payload, status: true });

      await userQuery.refetch();

      handleResultApi(response);

      setIsSignInModalModal(false);
    },
    onError: (error) => {
      handleResultApi(error.response?.data);
      setIsSignInModalModal(false);
    },
    ...options,
  });
};

export const useSignUpMutation = (options) => {
  const setIsSignUpModalModal = useSetRecoilState(signUpModalState);
  return useMutation({
    mutationFn: authApi.signUp,
    onSuccess: (response) => {
      handleResultApi(response);
      setIsSignUpModalModal(false);
    },
    onError: (error) => {
      handleResultApi(error.response?.data);
      setIsSignUpModalModal(false);
    },
    ...options,
  });
};

export const useForgotPasswordMutation = (options) => {
  const setIsForgotPasswordModalModal = useSetRecoilState(forgotPasswordModalState);
  return useMutation({
    mutationFn: authApi.forgotPassword,
    onSuccess: (response) => {
      handleResultApi(response);
      setIsForgotPasswordModalModal((pre) => ({
        ...pre,
        status: FORGOT_PASSWORD_STATUS.NOTIFICATION,
      }));
    },
    onError: (error) => {
      handleResultApi(error.response?.data);
    },
    ...options,
  });
};
