/* eslint-disable camelcase */
import { Button, Form, Input, Modal } from "antd";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useSignUpMutation } from "src/react-query/hooks/auth";
import { signUpModalState } from "src/recoil/commonRecoilState";
import isNumeric from "src/utils/isNumeric";
import { isRegexVietnamesePhoneNumber } from "src/validations";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function SignUpModal() {
  const signUpMutation = useSignUpMutation();

  const router = useRouter();
  const isFromLanding = router.query?.["landing"];

  useEffect(() => {
    if (isFromLanding === "sign-up") {
      setIsSignUpModalModal(true);
    }
  }, [isFromLanding]);

  const [form] = Form.useForm();
  const initialValues = {
    phone_number: "",
    name: "",
    password: "",
    password_confirmation: "",
  };

  const [isSignUpModalOpen, setIsSignUpModalModal] = useRecoilState(signUpModalState);
  const handleCancelSignUpModal = () => {
    setIsSignUpModalModal(false);
    form.resetFields();
  };

  const submitForm = async (data) => {
    const { phone_number, name, password, password_confirmation } = data;
    const payload = { phone_number, name, password, password_confirmation };
    signUpMutation.mutate(payload);
    form.resetFields();
  };

  return (
    <Modal
      maskClosable={false}
      title={<h2 className="tw-px-[20px] tw-font-bold tw-text-[20px]">Đăng ký</h2>}
      open={isSignUpModalOpen}
      onCancel={handleCancelSignUpModal}
      footer={null}
    >
      <Form
        {...layout}
        form={form}
        onFinish={submitForm}
        initialValues={initialValues}
        colon={false}
      >
        <Form.Item
          label={
            <p className="tw-font-medium tw-text-[16px] tw-relative tw-top-2">Số điện thoại</p>
          }
          name="phone_number"
          rules={[
            () => ({
              required: true,
              validator(_, value) {
                const phoneNumber = value.trim();
                const validate = isRegexVietnamesePhoneNumber(value);
                if (!phoneNumber) {
                  return Promise.reject("Vui lòng nhập số điện thoại");
                }
                if (isNaN(phoneNumber)) {
                  return Promise.reject("Số điện thoại không hợp lệ");
                }
                if (!validate) {
                  return Promise.reject("Số điện thoại không hợp lệ");
                }
                return Promise.resolve();
              },
            }),
          ]}
          normalize={(value) => {
            if (!isNumeric(value)) {
              return value?.substring(0, value.length - 1).trim();
            }
            return value.trim();
          }}
        >
          <Input className="focus:tw-border-red focus:tw-shadow-button tw-h-[40px]" />
        </Form.Item>

        <Form.Item
          label={<p className="tw-font-medium tw-text-[16px] tw-relative tw-top-2">Tên của bạn</p>}
          name="name"
          rules={[
            { required: true, message: "Vui lòng nhập tên của bạn" },
            { max: 50, message: "Tên nhỏ hơn 50 kí tự" },
            {
              whitespace: true,
              message: "Vui lòng nhập tên của bạn",
            },
          ]}
          normalize={(value) => value.trimStart()}
        >
          <Input className="focus:tw-border-red focus:tw-shadow-button tw-h-[40px]" />
        </Form.Item>

        <Form.Item
          label={<p className="tw-font-medium tw-text-[16px] tw-relative tw-top-2">Mật khẩu</p>}
          name="password"
          rules={[
            {
              required: true,
              validator(_, value) {
                if (!value) {
                  return Promise.reject("Vui lòng nhập mật khẩu");
                }

                if (!value.trim()) {
                  return Promise.reject("Mật khẩu không hợp lệ");
                }

                if (value.length < 6) {
                  return Promise.reject("Mật khẩu lớn hơn 6 kí tự");
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password className="focus:tw-border-red focus:tw-shadow-button tw-h-[40px]" />
        </Form.Item>
        <Form.Item
          label={
            <p className="tw-font-medium tw-text-[16px] tw-relative tw-top-2">Xác nhận mật khẩu</p>
          }
          name="password_confirmation"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Vui lòng xác nhận lại mật khẩu",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Hai mật khẩu bạn đã nhập không khớp!");
              },
            }),
          ]}
        >
          <Input.Password className="focus:tw-border-red focus:tw-shadow-button tw-h-[40px]" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            loading={signUpMutation.isLoading}
            className="tw-bg-button !tw-border-0 tw-min-w-[150px] tw-mt-[20px] tw-text-[20px] tw-h-[40px] tw-font-semibold"
            type="primary"
            htmlType="submit"
          >
            Đăng ký
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
