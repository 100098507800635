import { useSetRecoilState } from "recoil";
import { signInModalState, signUpModalState } from "src/recoil/commonRecoilState";
import SignInModal from "src/components/Auth/SignInModal";
import SignUpModal from "src/components/Auth/SignUpModal";
import ForgotPasswordModal from "src/components/Auth/ForgotPasswordModal";

const textWhite16px = `lg:!tw-text-[white] tw-text-black !tw-text-[16px]`;
const separator18px = `!tw-text-orange !tw-text-[18px]`;

export default function BtnGroupRightHeader() {
  const setIsSignUpModalModal = useSetRecoilState(signUpModalState);
  const setIsSignInModalModal = useSetRecoilState(signInModalState);

  return (
    <div>
      <div className="tw-authentication tw-flex tw-items-center tw-text-blue">
        <button onClick={() => setIsSignUpModalModal(true)} className={textWhite16px}>
          Đăng ký
        </button>
        <span className={`${separator18px} tw-mx-[12px]`}> {"|"}</span>
        <button onClick={() => setIsSignInModalModal(true)} className={textWhite16px}>
          Đăng nhập
        </button>
        {/* <div className="tw-ml-[35px] tw-cursor-pointer">
          <AiOutlineSearch fontSize={24} color="#ff7849" />
        </div> */}
      </div>
      <SignUpModal />
      <SignInModal />
      <ForgotPasswordModal />
    </div>
  );
}
