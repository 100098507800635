const screens = {
  xs: 320,
  // => @media (min-width: 320) { ... }
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  desktop: 1536,
};

export default screens;
