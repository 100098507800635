import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import queryKeys from "src/react-query/constants";
import { authState, authStateDefault } from "src/recoil/authRecoilState";
import { removeCookie } from "src/utils/cookie";

export default function useAuthService() {
  const queryClient = useQueryClient();
  const [auth, setAuth] = useRecoilState(authState);

  const handleLogout = () => {
    queryClient.setQueryData([queryKeys.user.getUserInfo], () => null);
    setAuth(authStateDefault);
    removeCookie();
  };

  return { handleLogout, auth, setAuth };
}
