import { Divider, Image, Popover } from "antd";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { BiLogOut } from "react-icons/bi";
import { BsFillPeopleFill } from "react-icons/bs";
import { CgFileDocument } from "react-icons/cg";
import { IoMdArrowDropdown } from "react-icons/io";
import AvatarDefault from "src/assets/images/avatar_default.png";
import { routerConstant } from "src/constants/routerConstant";
import useAuthService from "src/hooks/useAuthService";
import { useGetUserInfo } from "src/react-query/hooks/user";

const AvatarAuth = () => {
  const router = useRouter();
  const userQuery = useGetUserInfo();
  const dataUser = userQuery?.data?.data?.user;

  const menuItem =
    "tw-flex tw-items-center tw-gap-[18px] !tw-text-white hover:!tw-text-orange-dark tw-cursor-pointer";

  const { handleLogout } = useAuthService();

  const menuAuth = useMemo(
    () => (
      <div className="tw-text-orange tw-px-[24px] tw-py-[26px] tw-font-medium tw-text-[16px] tw-leading-[25px] tw-w-[240px]">
        <div className="tw-flex tw-gap-[20px] tw-items-center">
          <div>
            <Image
              src={dataUser?.avatar || AvatarDefault.src}
              alt="Ảnh đại diện"
              width={40}
              height={40}
              preview={false}
              className="tw-cursor-pointer tw-border-[1px] tw-border-solid tw-border-gray37 tw-rounded-full tw-p-[3px]"
            />
          </div>

          <div>
            <div className="tw-font-bold tw-m">{dataUser?.name}</div>
            <div>{dataUser?.phone_number}</div>
          </div>
        </div>

        <Divider style={{ background: "white" }} />

        <div className="tw-flex tw-flex-col tw-gap-[18px] tw-text-orange">
          <div onClick={() => router.push(routerConstant.profile)} className={menuItem}>
            <BsFillPeopleFill fontSize={20} color="#ff7849" />
            <div className="tw-text-orange">Thông tin cá nhân</div>
          </div>

          <div onClick={() => router.push(routerConstant.userHistory)}>
            <div className={menuItem}>
              <CgFileDocument fontSize={20} color="#ff7849" />
              <div className="tw-text-orange">Lịch sử đặt lịch</div>
            </div>
          </div>

          <div onClick={handleLogout} className={menuItem}>
            <BiLogOut fontSize={20} color="#ff7849" />
            <div className="tw-text-orange">Đăng xuất</div>
          </div>
        </div>
      </div>
    ),
    [dataUser?.avatar, dataUser?.name, dataUser?.phone_number, handleLogout, router],
  );
  return (
    <div>
      <Popover
        content={menuAuth}
        trigger="hover"
        overlayClassName="custom-popover tw-backdrop-brightness-[0.3]"
        placement="bottomRight"
      >
        <div className="tw-flex tw-items-center tw-gap-[10px]">
          <Image
            src={AvatarDefault.src}
            alt="Ảnh đại diện"
            width={40}
            preview={false}
            className="tw-cursor-pointer tw-border-[1px] tw-border-solid tw-border-gray37 tw-rounded-full tw-p-[3px]"
          />
          <div className="lg:tw-hidden tw-flex tw-items-center tw-gap-[10px]">
            <div className="tw-text-[18px] tw-font-bold tw-text-black">{dataUser?.name}</div>
            <IoMdArrowDropdown fontSize={24} />
          </div>
        </div>
      </Popover>
    </div>
  );
};
export default AvatarAuth;
