import {
  AiFillLinkedin,
  AiFillYoutube,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { MdPlace, MdWifiCalling3 } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import DownloadAppStore from "src/assets/images/app-store.png";
import DownloadGooglePlay from "src/assets/images/google-play.png";
import { Image } from "antd";
const SocialIconList = [
  { id: 1, Icon: FaFacebookF },
  { id: 2, Icon: AiOutlineInstagram },
  { id: 3, Icon: AiOutlineTwitter },
  { id: 4, Icon: AiFillYoutube },
  { id: 5, Icon: AiFillLinkedin },
];

export const SocialIcons = ({ Icon }) => {
  return (
    <div className="tw-h-[27px] tw-w-[27px] tw-border-[2px] tw-border-solid tw-border-white tw-flex tw-items-center tw-justify-center tw-rounded-[9999px] tw-cursor-pointer">
      <a href="https://www.facebook.com/AutoShine.com.vn/">
        <Icon />
      </a>
    </div>
  );
};

export default function Footer() {
  return (
    <div className="tw-px-[20px] md:tw-px-[130px] tw-bg-black tw-min-h-[285px]  tw-text-white tw-pt-[70px] tw-pb-[25px]">
      <div className="tw-flex tw-items-center tw-justify-between tw-text-[20px] tw-flex-wrap tw-space-[20px] tw-gap-[40px]">
        <div className="sm:tw-min-w-[450px] tw-min-w-auto">
          <div className="tw-text-[36px] tw-leading-[36px] tw-mb-[25px] tw-uppercase">
            AutoShine
          </div>

          <div className="tw-mb-[22px]">
            <div className="tw-flex tw-items-center tw-gap-[20px] tw-mb-[16px]">
              <div className="tw-w-[22px] tw-h-[22px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center">
                <MdPlace color="#000" fontSize={16} />
              </div>
              <div className="tw-text-[16px] tw-leading-[20px]">
                127 Võ Chí Công, Xuân La, Tây Hồ, Hà Nội
              </div>
            </div>

            <div className="tw-flex tw-items-center tw-gap-[20px]">
              <div className="tw-w-[22px] tw-h-[22px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center">
                <IoIosMail color="#000" fontSize={16} />
              </div>
              <div className="tw-text-[16px] tw-leading-[20px]">
                Email: <span className="tw-text-[20px]">autoshinevn@gmail.com </span>
              </div>
            </div>
          </div>

          <div className="sm:tw-w-[440px] tw-w-auto tw-border-solid tw-border-2 tw-border-orange-dark tw-rounded-[10px] tw-text-center tw-flex tw-items-center tw-justify-center tw-gap-[24px] tw-py-[14px]">
            <div className="tw-w-[24px] tw-h-[24px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center">
              <MdWifiCalling3 color="#000" fontSize={22} />
            </div>

            <div className="tw-text-[20px] tw-leading-[22px]">
              Hotline: <span className="tw-text-[22px] tw-ml-[15px]">0933 627988</span>
            </div>
          </div>
        </div>

        <div className="tw-min-w-[220px] tw-uppercase tw-text-[22px] tw-leading-[38px]">
          <p> Giới thiệu</p>
          <p> Chính sách</p>
          <br />
          <p>Quyền riêng tư</p>
          <p> chính sách bảo mật</p>
        </div>

        <div className="tw-w-[330px]">
          <div className="tw-text-[22px] tw-leading-[28px] tw-mb-[10px] tw-font-bold tw-uppercase">
            Tải ứng dụng của Autoshine
          </div>
          <div className="tw-text-[17px] tw-leading-[20px] tw-mb-[36px] sm:tw-whitespace-pre-wrap tw-whitespace-normal">
            {`Trải nghiệm đặt lịch nhanh chóng và \nnhiều tiện ích khác với ứng dụng AutoShine`}
          </div>
          <div className="tw-flex tw-gap-[22px]">
            <Image
              src={DownloadGooglePlay.src}
              alt="download"
              preview={false}
              className="tw-cursor-pointer"
            />
            <Image
              src={DownloadAppStore.src}
              alt="download"
              preview={false}
              className="tw-cursor-pointer"
            />
          </div>
        </div>
      </div>

      <div className="tw-w-full tw-h-[1px] tw-bg-white tw-mt-[70px] tw-mb-[35px]" />
      <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
        <div>
          <p className="tw-text-[18px] tw-text-white">Copyright &copy; 2022 AUTOSHINE</p>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-space-x-[5px]">
          {SocialIconList.map(({ id, Icon }) => (
            <SocialIcons key={id} Icon={Icon} />
          ))}
        </div>
      </div>
    </div>
  );
}
